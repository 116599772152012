import React from "react";
import styles from "../styles/Footer.module.css";
import { Box, Container, Grid, Typography } from "@mui/material";

type Props = {};

const Footer = ({}: Props) => {
  return (
    <footer className={styles.footerContainer}>
      <Container maxWidth="lg">
        <Grid container sx={{ flexDirection: "row" }}>
          <Grid item sx={{ flex: 1, textAlign: "center" }} mb={4}>
            <Typography variant="h2" className={styles.title} mb={3}>
              <a href="https://thedarkhorse.xyz">The Dark Horse</a>
            </Typography>
            <Box sx={{ fontSize: 12 }}>
              <div>&copy; Digital Creators 2022</div>
              <div>All rights reserved</div>
            </Box>
          </Grid>

          {/*<Grid container item className={styles.footerNav} spacing={2}*/}
          {/*      sx={{flexDirection: {xs: "column", md: "row"}, alignContent: {xs: "center", sm: "end", md: "start"},*/}
          {/*      textAlign: {xs: "center", sm: "end", md: "start"}, justifyContent: {md: "end"}}}>*/}
          {/*    <Grid item><a href="https://www.thedarkhorse.xyz">Home</a></Grid>*/}
          {/*    <Grid item><a href="https://www.thedarkhorse.xyz">The Drop</a></Grid>*/}
          {/*    <Grid item><a href="https://www.thedarkhorse.xyz">Roadmap</a></Grid>*/}
          {/*    <Grid item><a href="https://www.thedarkhorse.xyz">FAQ</a></Grid>*/}
          {/*    <Grid item><a href="https://www.thedarkhorse.xyz">Team</a></Grid>*/}
          {/*</Grid>*/}
        </Grid>
        {/*<Grid container className={styles.footerLinks} spacing={2} mt={4} sx={{fontSize: 12,*/}
        {/*flexDirection: {xs: "column", sm: "row"},*/}
        {/*    alignContent: {xs: "center", sm: "start"},*/}
        {/*    textAlign: {xs: "center", sm: "start"}}}>*/}
        {/*    <Grid item><a href="https://www.thedarkhorse.xyz/cookies">Cookies</a></Grid>*/}
        {/*    <Grid item><a href="https://www.thedarkhorse.xyz/privacy">Privacy Policy</a></Grid>*/}
        {/*    <Grid item><a*/}
        {/*        href="https://www.thedarkhorse.xyz/termsandconditions">Terms &amp; Conditions</a></Grid>*/}
        {/*</Grid>*/}
      </Container>
    </footer>
  );
};

export default Footer;
