import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import { responsiveFontSizes } from "@mui/material";

// Create a theme instance.
let theme = createTheme({
  palette: {
    primary: {
      main: "#556cd6",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
  },
  typography: {
    fontFamily: ["SoehneBreit"].join(","),
  },
});

theme.spacing(2);
theme = responsiveFontSizes(theme);

export default theme;
