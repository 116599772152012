import "../styles/global.css";
import { AppProps } from "next/app";
import Head from "next/head";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { CacheProvider } from "@emotion/react";
import Axios from "../lib/api";
import Layout from "../components/Layout";
import theme from "../src/theme";
import createEmotionCache from "../src/createEmotionCache";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { pageview } from "../lib/gtm";
import ENV from "../lib/env";
import Script from "next/script";
import AppContext from "../components/AppContext";

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const defaultQueryFn = async ({ queryKey }: any) => {
  const response = await Axios.get(queryKey[0]);

  return response.data;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      queryFn: defaultQueryFn as any,
    },
  },
});

function DarkHorse({ Component, pageProps }: AppProps) {
  const [session, setSession] = useState();

  const router = useRouter();
  useEffect(() => {
    router.events.on("routeChangeComplete", pageview);
    return () => {
      router.events.off("routeChangeComplete", pageview);
    };
  }, [router.events]);

  return (
    <CacheProvider value={clientSideEmotionCache}>
      <AppContext.Provider value={{ session, setSession }}>
        {/* Google Tag Manager - Global base code */}
        <Script
          id="gtag-base"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer', '${ENV.GTM_ID}');
                          `,
          }}
        />
        <Head>
          <meta name="viewport" content="initial-scale=1, width=device-width" />
        </Head>
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon.
           * commented to not override our global.css
           */}
          <CssBaseline />
          <QueryClientProvider client={queryClient}>
            <Layout>
              <Component {...pageProps} />
            </Layout>
            <ReactQueryDevtools />
          </QueryClientProvider>
        </ThemeProvider>
      </AppContext.Provider>
    </CacheProvider>
  );
}

// MyApp.propTypes = {
//     Component: PropTypes.elementType.isRequired,
//     emotionCache: PropTypes.object,
//     pageProps: PropTypes.object.isRequired,
//   };

export default DarkHorse;
