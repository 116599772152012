export function getToken(): string | null {
  return localStorage.getItem("authToken");
}

export function getTokenForAddress(address: string): string | null {
  const storedAddress = localStorage.getItem("authToken");
  if (storedAddress !== address) {
    return null;
  }

  return getToken();
}

export function setToken(token: string, address: string) {
  localStorage.setItem("authToken", token);
  localStorage.setItem("address", address);
}

export function clearToken() {
  localStorage.removeItem("authToken");
  localStorage.removeItem("address");

  localStorage.removeItem("token"); // temporary to clean up saved users
  localStorage.removeItem("user"); // temporary to clean up saved users
}
