import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import styles from "../styles/Navbar.module.css";

type Props = {};

const Navbar = (props: Props) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <Grid
      container
      className={styles.container}
      py={2}
      px="40px"
      sx={{ textAlign: "center" }}
    >
      <Grid item sx={{ flex: 1 }}>
        <Typography variant="h1" className={styles.title} py={1}>
          <a href="https://thedarkhorse.xyz">THE DARK HORSE</a>
        </Typography>
      </Grid>

      {/*{(isDesktop) ? (*/}
      {/*  <Grid*/}
      {/*    container*/}
      {/*    columnSpacing={3}*/}
      {/*    justifyContent="space-around"*/}
      {/*    alignItems="stretch"*/}
      {/*    key={"whitelist-container"}*/}
      {/*    className={styles.exploitedHamburger}>*/}
      {/*    <p>HOME</p>*/}
      {/*    <p>THE DROP</p>*/}
      {/*    <p>ROADMAP</p>*/}
      {/*    <p>FAQ</p>*/}
      {/*    <p>TEAM</p>*/}
      {/*  </Grid>*/}
      {/*) : (*/}
      {/*  <div className={styles.hamburger}>*/}
      {/*    <Hamburger size={20}*/}
      {/*      rounded*/}
      {/*      toggled={isOpen} */}
      {/*      toggle={setOpen}*/}
      {/*      onToggle={toggled => {console.log(`hamburger ${toggled}`);}} />*/}
      {/*  </div>*/}
      {/*)*/}
      {/*}*/}
    </Grid>
  );
};

export default Navbar;
