const ENV_VARIABLES_RAW = {
  ENVIRONMENT: process.env.NODE_ENV,
  API_HTTP_URL: process.env.NEXT_PUBLIC_API_HTTP_URL,
  SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  SENTRY_ENVIRONMENT: process.env.NEXT_PUBLIC_SENTRY_HOST_ENVIRONMENT,
  SENTRY_TRACES_SAMPLE_RATE: process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE,
  GA_MEASUREMENT_ID: process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID,
  GTM_ID: process.env.NEXT_PUBLIC_GTM_ID,
  ROBOTS_META_CONTENT: process.env.NEXT_PUBLIC_ROBOTS_META_CONTENT,
  CONTRACT_ADDRESS: process.env.NEXT_PUBLIC_CONTRACT_ADDRESS,
  OG_WINDOW_START_DATETIME: process.env.NEXT_PUBLIC_OG_WINDOW_START_DATETIME,
  PUBLIC_START_DATETIME: process.env.NEXT_PUBLIC_PUBLIC_START_DATETIME,
  WEB3_PROVIDER: process.env.NEXT_PUBLIC_WEB3_PROVIDER,
};

const ENV = {
  ...ENV_VARIABLES_RAW,

  IS_PRODUCTION: process.env.NODE_ENV === "production",
  IS_DEVELOPMENT: process.env.NODE_ENV === "development",
  IS_TEST: process.env.NODE_ENV === "test",
  ROBOTS_META_CONTENT: process.env.NEXT_PUBLIC_ROBOTS_META_CONTENT || "",
  OG_WINDOW_START_DATETIME: Date.parse(
    process.env.NEXT_PUBLIC_OG_WINDOW_START_DATETIME!
  ),
  PUBLIC_START_DATETIME: Date.parse(
    process.env.NEXT_PUBLIC_PUBLIC_START_DATETIME!
  ),
};

if (ENV.IS_DEVELOPMENT) {
  console.log(ENV);
}

export default ENV;
